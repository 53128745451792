import React from 'react'
import { OrangeLayout } from '../components';
import config from '../../config'
import Helmet from 'react-helmet'

const NotFoundPage = () => <OrangeLayout title="That URL doesn't point anywhere">
    <Helmet title={`404 | ${config.siteTitle}`}/>
    <p>Quoth the server, 404.</p>
</OrangeLayout>

export default NotFoundPage;